.container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
}

.left {
  background-color: #333;
  min-width: 20px;
  max-width: 1000px;
  width: 30%;
}

.right {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding: 50px;
  align-items: flex-start;
}

.popup-back {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: all;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.popup {
  width: 90%;
  max-width: 400px;
  border-radius: 5px;
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.popup > h3 {
  margin: 0;
}

.popup > button {
  align-self: flex-end;
  border: none;
  background: black;
  color: white;
  border-radius: 3px;
  padding: 5px 15px;
}

.gallery {
  height: 500px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  margin: 20px 0 50px;
  overflow: hidden;
}

img {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.gallery > button {
  position: absolute;
  background: white;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
}

.leftBtn {
  left: 10px;
}

.rightBtn {
  left: 50px;
}

h1 {
  font-size: 5em;
}

span {
  font-size: 1.25em;
}

.right > button {
  align-self: flex-end;
  border: none;
  background: black;
  color: white;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 15px;
}

button {
  cursor: pointer;
}

p {
  font-size: 16px;
}
